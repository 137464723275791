@font-face {
font-family: 'roboto';
src: url(/_next/static/media/a4eae32d320f45e6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/43a343143e72a1ca-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/f2894edcf7d09d36-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/8ed3d75f3892762e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/3170fd9aa9258fe0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/04452ce88d6a3c12-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/71b2beb82c4d1389-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/055bb51fb7f62c90-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/2f95c19ebf5cbe2e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/42aad12fea4631c8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: 'roboto Fallback';src: local("Arial");ascent-override: 92.49%;descent-override: 24.34%;line-gap-override: 0.00%;size-adjust: 100.30%
}.__className_d185dc {font-family: 'roboto', 'roboto Fallback'
}.__variable_d185dc {--font-roboto: 'roboto', 'roboto Fallback'
}

@font-face {
font-family: 'baloo';
src: url(/_next/static/media/d86cb100f71d6b8d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400 800;
font-style: normal;
}@font-face {font-family: 'baloo Fallback';src: local("Arial");ascent-override: 111.20%;descent-override: 54.05%;line-gap-override: 0.00%;size-adjust: 96.95%
}.__className_66897f {font-family: 'baloo', 'baloo Fallback';font-style: normal
}.__variable_66897f {--font-baloo: 'baloo', 'baloo Fallback'
}

